import { useState } from "react";
import { createContext } from "react";

const UserContext= createContext();
const UserProvider=({children})=>{
    const initialState={
        header:true,
        skills:false,
        contact:false,
        html:false,
        reactPage:false,
        php:false,
        wordpress:false
    }
    const [sections, setSections] = useState(initialState)
    const data={sections, setSections}
    return(
        <UserContext.Provider value={data}>
            {children}
        </UserContext.Provider>
    )
}
export {UserProvider}
export default UserContext
