import React from 'react'
import { useContext } from 'react';
import UserContext from '../contexts/UserContext';
import { useForm } from "react-hook-form";
import { useState } from 'react';

const Contact = () => {
  const [isSentEmail,setEmail] = useState(false)
  const data=useContext(UserContext);
  const {sections}=data;
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const sendEmail=async(dataForm)=>{
    if(dataForm){
        const resp=await fetch("https://victorjreyes.com/email/index.php",{
        method: "POST",
        body: JSON.stringify(dataForm),
        headers: {
          "content-type": "application/json",
        }
      })
      const data=await resp.text();

      console.log('la data es :'+data);
      console.log(typeof data);
     if(parseInt(data)===1){
      reset();
      setEmail(!isSentEmail);
      setTimeout(()=>{
        setEmail(false); 
      },10000)
     }
     else{
      alert("The email was not sent")
     }
    }

  }
  return (
   <section className={sections.contact?"contact":"d-none"} id="contact">
    <h4 className='contact__title1'>Get In Touch</h4>
    <h2 className='contact__title2'>Contact Me</h2>
    <h2 className='contact__title1'>Whatsapp: +34617052967</h2>
    <div className='contact__container'>
      <div>
      
      </div>
      <div >
        <form className='contact__form' onSubmit={handleSubmit(sendEmail)}>
          <input type="text" placeholder='Your Name...'  className='contact__input' selected autoComplete="off"
          {...register('name',{
            required: true,
            maxLength:30
          })}
          />
         {
          errors.name?.type==="required" && <span className='msgError'>Name is required</span>
         }
          {
          errors.name?.type==="maxLength" && <span className='msgError'>Name Max Length 30 characters</span>
         }
          <input type="text" placeholder='Your Email...' className='contact__input' autoComplete="off"
           {...register("email", {
            required: true,
            maxLength: 60,
            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/,
          })}
          />
           {errors.email?.type === "required" && (
                  <span className="msgError">Email is required</span>
                )}
                {errors.email?.type === "maxLength" && (
                  <span className="msgError">
                    Email max length: 60 characters{" "}
                  </span>
                )}
                {errors.email?.type === "pattern" && (
                  <span className="msgError">Fromat invalid</span>
                )}
          <input type="text" placeholder='Your Phone Number...' className='contact__input' autoComplete="off"
           {...register('phonenumber',{
            required: true,
            maxLength:20,
            pattern: /^[0-9 ]+$/i,
          })}          
          />
          {
          errors.phonenumber?.type==="maxLength" && <span className='msgError'>Phone Number Max Length 20 characters</span>
         }
          {errors.phonenumber?.type === "pattern" && (
                  <span className="msgError">Format invalid only numbers</span>
                )}
          <input type="text" placeholder='Subject....' className='contact__input' autoComplete="off"
           {...register('subject',{
            required: true,
            maxLength:100
          })}
          />
          {
          errors.subject?.type==="required" && <span className='msgError'>Subject is required</span>
         }
          {
          errors.subject?.type==="maxLength" && <span className='msgError'>Subject Max Length 100 characters</span>
         }
          <textarea name="" id="" cols="30" rows="10" placeholder='Comments' className='contact__textarea' autoComplete="off"
           {...register('comments',{
            required: true,
            maxLength:500
          })}
          ></textarea>
           {
          errors.comments?.type==="required" && <span className='msgError'>Comments is required</span>
         }
          {
          errors.comments?.type==="maxLength" && <span className='msgError'>Comments Max Length 500 characters</span>
         }
        <button className='contact__button'>Submit</button>
        </form>
        <div className={isSentEmail?"msgSend":"d-none"}>
          <p>The email was sent successfully. I will send you a response in a shortly.</p>
        </div>
      </div>
    </div>
   </section>
  )
}

export default Contact