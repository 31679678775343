import React from 'react';
import 'animate.css'
import { useContext } from 'react';
import UserContext from '../contexts/UserContext';

const Header = () => {
  const data=useContext(UserContext);
  const {sections, setSections}=data;
  const downloadCV=()=>
  {
    window.open("https://victorjreyes.com/Victor_Reyes_cv.pdf");
  }
  const contact=()=>{
    const data={
      header:false,
      skills:false,
      contact:true,
      html:false,
      reactPage:false,
      php:false,
      wordpress:false
    }
    setSections(data);
  }
  return (
   <section className={sections.header?"header":"d-none"} id="header">

  <h2 className="header__hello">Hello I am</h2>
   <h1 className="header__name">Victor Reyes</h1>
   <h2 className="header__position">Fullstack Developer</h2>
   <div className="header__box">

        <button className="header__cv" onClick={downloadCV}>Download CV</button>
      <button className="header__contact" onClick={contact}>Let's talk</button>
   </div>


 <div className="header__content">
  <div className="header__containerimg">
   <img src="/images/victor1.png" alt="Victor Reyes" className='header__profileimg'/>
    </div>
    {/* <div className='header__about'>
    <p style={{fontSize:20,textAlign:"justify",lineHeight:2}}>Hello i am a Systems Engineer, focused on web development and digital marketing. I have excellent interpersonal relationships, adaptability to change, leadership skills and teamwork.</p>
    <p style={{fontSize:20,textAlign:"justify",lineHeight:2}}><strong>Skills: </strong> Html, css3, Javascript, React, Node, Mongodb, PHP, Mysql Laravel</p>
    <p style={{fontSize:20,textAlign:"justify",lineHeight:2}}><strong>Languages: </strong> Spanish, English</p>
   
    </div> */}
  </div>

   
   </section>
  )
}

export default Header