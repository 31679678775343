import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const Skills = () => {
  const data = useContext(UserContext);
  const { sections, setSections } = data;

  const htmlProjects = () => {
    const data = {
      header: false,
      skills: false,
      contact: false,
      html: true,
      reactPage: false,
      php: false,
      wordpress: false,
    };
    setSections(data);
  };
  const phpProjects = () => {
    const data = {
      header: false,
      skills: false,
      contact: false,
      html: false,
      reactPage: false,
      php: true,
      wordpress: false,
    };
    setSections(data);
  };
  const reactProjects = () => {
    const data = {
      header: false,
      skills: false,
      contact: false,
      html: false,
      reactPage: true,
      php: false,
      wordpress: false,
    };
    setSections(data);
  };
  const wordpressProjects = () => {
    const data = {
      header: false,
      skills: false,
      contact: false,
      html: false,
      reactPage: false,
      php: false,
      wordpress: true,
    };
    setSections(data);
  };

  return (
    <>
      <section
        className={sections.skills ? "projects" : "d-none"}
        id="projects"
      >
        <h4 className="projects__title1">Check My</h4>
        <h2 className="projects__title2">Skills</h2>
        {/*   */}
        <div className="projects__container">
          <div className="projects__card">
            <img src="images/html.jpg" alt="HTML" className="projects__image" />
            <div className="projects__content">
              <h2 className="projects__titlecard">Html, css, Javascript</h2>
              <button className="projects__button" onClick={htmlProjects}>
                <span>See Templates...</span>
              </button>
            </div>
          </div>
          {/* end skill */}
          <div className="projects__card">
            <img src="images/php.jpg" alt="PHP" className="projects__image" />
            <div className="projects__content">
              <h2 className="projects__titlecard">PHP</h2>
              <button className="projects__button" onClick={phpProjects}>
             <span>  See Projects...</span>
              </button>
            </div>
          </div>
          {/* end skill */}
          <div className="projects__card">
            <img
              src="images/react.jpg"
              alt="React"
              className="projects__image"
            />
            <div className="projects__content">
              <h2 className="projects__titlecard">React</h2>
              <button className="projects__button" onClick={reactProjects}>
             <span>  See Projects...</span>
              </button>
            </div>
          </div>
          {/* end skill */}
          <div className="projects__card">
            <img
              src="images/wordpress.jpg"
              alt="Wordpress"
              className="projects__image"
            />
            <div className="projects__content">
              <h2 className="projects__titlecard">Wordpress</h2>
              <button className="projects__button" onClick={wordpressProjects}>
               <span> See Projects...</span>
              </button>
            </div>
          </div>
          {/* end skill */}
        </div>
      </section>
    </>
  );
};

export default Skills;
