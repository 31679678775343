import Contact from "../components/Contact";
import Header from "../components/Header";
import MenuMobile from "../components/MenuMobile";
import NavBar from "../components/NavBar";
import Skills from "../components/Skills";
import Html from "../pages/Html";
import Php from "../pages/Php";
import ReactPage from "../pages/React";
import Wordpress from "../pages/Wordpress";

const Home = () => {
  return (
    <>
      <NavBar />
      <Header />
      <Skills />
      <Html />
      <Php />
      <ReactPage />
      <Wordpress />
      <Contact />
      <MenuMobile/>
    </>
  );
};

export default Home;
