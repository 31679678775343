import React, { useState } from "react";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const MenuMobile = () => {
  const data = useContext(UserContext);
  const {setSections } = data;
  const [isOpen, setOpen] = useState(false);

  const openmenu = () => {
    setOpen(!isOpen);
  };

  const home = () => {
    const data = {
      header: true,
      skills: false,
      contact: false,
      html: false,
      reactPage: false,
      php: false,
      wordpress: false,
    };
    setSections(data);
    setOpen(!isOpen);
  };

  const skills = () => {
    const data = {
      header: false,
      skills: true,
      contact: false,
      html: false,
      reactPage: false,
      php: false,
      wordpress: false,
    };
    setSections(data);
    setOpen(!isOpen);
  };
  const contact = () => {
    const data = {
      header: false,
      skills: false,
      contact: true,
      html: false,
      reactPage: false,
      php: false,
      wordpress: false,
    };
    setSections(data);
    setOpen(!isOpen);
  };
  return (
    <>
      <div className={isOpen ? "menu menufull" : "menu"}>
        <ul>
          <li id="contacto">
            <span
              className="fa fa-envelope icon"
              title="Contact"
              alt="contact"
              onClick={contact}
            ></span>
          </li>
          <li id="skills">
            <span
              className="fa fa-book icon"
              title="Skills"
              alt="skills"
              onClick={skills}
            ></span>
          </li>
          <li id="home">
            <span
              className="fa fa-home icon"
              title="Home"
              alt="home"
              onClick={home}
            ></span>
          </li>
        </ul>
      </div>
      <div
        className={isOpen ? "open-menu pulsed" : "open-menu"}
        onClick={openmenu}
      ></div>
    </>
  );
};

export default MenuMobile;
