import { useContext } from 'react';
import UserContext from '../contexts/UserContext';

const ReactPage = () => {
    const data=useContext(UserContext);
    const {sections}=data;

    const Marketplace=()=>{
        window.open("https://localfete.com");
    }
    const Bullingdon=()=>{
        window.open("https://bullingdon.victorjreyes.com");
    }
    const Countries=()=>{
        window.open("https://countries.victorjreyes.com");
    }
  return (
<>
  
<section className={sections.reactPage?"container":"d-none"}>

<h2 className='title'>React, Node JS, Mongo DB</h2>  
<div className="cards">
<div className="card">
    <img src="images/localfeteProject.png" alt="Marketplace" className='card__image' />
    <div className="card__layout"></div>
 <div className="card__box">
 <button className='card__btn' onClick={Marketplace}><span>Marketplace</span></button>
 </div>
</div>
<div className="card">
<img src="images/bl.png" alt="Landing Page" className='card__image' />
<div className="card__box">

<button className='card__btn' onClick={Bullingdon}><span>Landing Page</span></button>
</div>
</div>
<div className="card">
<img src="images/api_countries.png" alt="APi Countries" className='card__image' />
<div className="card__box">

<button className='card__btn' onClick={Countries}><span>API Countries</span></button>
</div>
</div>
</div>

</section>
</>
  )
}

export default ReactPage