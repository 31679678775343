import React from 'react'
// import { Link } from "react-scroll";
import { FaHome, FaEnvelope, FaBook} from "react-icons/fa";
import { useContext } from 'react';
import UserContext from '../contexts/UserContext';

const NavBar = () => {
  const data=useContext(UserContext);
  const {sections, setSections}=data;
  const home=()=>{
    const data={
      header:true,
      skills:false,
      contact:false,
      html:false,
      reactPage:false,
      php:false,
      wordpress:false
    }
    setSections(data);
  }


  const skills=()=>{
    const data={
      header:false,
      skills:true,
      contact:false,
      html:false,
      reactPage:false,
      php:false,
      wordpress:false
    }
    setSections(data);
  }
  const contact=()=>{
    const data={
      header:false,
      skills:false,
      contact:true,
      html:false,
      reactPage:false,
      php:false,
      wordpress:false
    }
    setSections(data);
  }

  return (
   <div className="navbar">
   
    <FaHome className={sections.header?'navbar__icon navbar__icon--active': 'navbar__icon'} onClick={home}/>
   
  
   {/* <Link
     activeClass="active"
     to="about"
     spy={true}
     smooth={true}
     offset={-59}
     duration={500}
   >
   <FaUserAlt className='navbar__icon'/>
   </Link> */}
    <FaBook className={sections.skills?'navbar__icon navbar__icon--active': 'navbar__icon'} onClick={skills}/>
  <FaEnvelope className={sections.contact?'navbar__icon navbar__icon--active': 'navbar__icon'} onClick={contact}/>

   </div>
  )
}

export default NavBar