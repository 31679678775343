import { useContext, useState } from 'react';
import UserContext from '../contexts/UserContext';

const Php = () => {
    const data=useContext(UserContext);
    const {sections}=data;
    const [isModal, setModal] = useState(false)

    const Dashboard=()=>{
        window.open("https://victorjreyes.com/lawyersystem");
    }
    const LandingPage=()=>{
        window.open("https://abogadosenextranjeriaeinversores.com");
    }
    const TodoList=()=>{
        window.open("https://todos.victorjreyes.com");
    }
  
  return (
<>

<section className={sections.php?"container":"d-none"}>

<h2 className='title'>PHP, Mysql, Javascript, Laravel</h2>  
<div className="cards">
<div className="card">
            <img
              src="images/dashboard.png"
              alt="Servicios de Extranjeria"
              className="card__image"
            />
            <div className="card__box">
              <button className="card__btn" onClick={Dashboard}>
                <span>Dashboard</span>
              </button>
            </div>
          </div>
          {/* end 1st card */}
          <div className="card">
            <img
              src="images/madrid.jpg"
              alt="Landing Page PHP"
              className="card__image"
            />
            <div className="card__box">
              <button className="card__btn" onClick={LandingPage}>
                <span>Landing Page</span>
              </button>
            </div>
          </div> 
           {/* end 2st card*/}
          <div className="card">
            <img
              src="images/blog.png"
              alt="Blog Laravel"
              className="card__image"
            />
            <div className="card__box">
              <button className="card__btn" onClick={TodoList}>
                <span>TodoList Laravel</span>
              </button>
            </div>
          </div>
</div>

</section>
<div className={isModal?"modal":"d-none"}>
     <div className="modal__container">
      <div className="modal__cerrar"><i className="fa-solid fa-circle-xmark modal__icon" onClick={()=>{setModal(!isModal)}}></i></div>
      <h5 className="modal__title">This site is under maintenance</h5>
      <div className="modal__button" id="hacer_pago" onClick={()=>{setModal(!isModal)}}>...Close...</div>
    </div>
     </div>
</>
  )
}

export default Php