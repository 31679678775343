import { useContext } from 'react';
import UserContext from '../contexts/UserContext';

const Html = () => {
    const data=useContext(UserContext);
    const {sections}=data;

    const Restaurante=()=>{
        window.open("https://victorjreyes.com/restaurant");
    }
    const Barberia=()=>{
        window.open("https://victorjreyes.com/barberia");
    }
    const Ski=()=>{
        window.open("https://victorjreyes.com/ski");
    }
  return (
<>
<section className={sections.html?"container":"d-none"}>

<h2 className='title'>HTML, CSS, Javascript</h2>  
<div className="cards">
<div className="card">
    <img src="images/restaurante.png" alt="Restaurante" className='card__image' />
 <div className="card__box">
 <button className='card__btn' onClick={Restaurante}><span>Restaurante</span></button>
 </div>
</div>
<div className="card">
<img src="images/barberia.png" alt="Barberia" className='card__image' />
<div className="card__box">

<button className='card__btn' onClick={Barberia}><span>Barberia</span></button>
</div>
</div>
<div className="card">
<img src="images/snow.jpg" alt="Snow" className='card__image' />
<div className="card__box">

<button className='card__btn' onClick={Ski}><span>Hotel</span></button>
</div>
</div>
</div>

</section>
</>
  )
}

export default Html