import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const Wordpress = () => {
  const data = useContext(UserContext);
  const { sections } = data;

  const Arquitecto = () => {
    window.open("https://javilah.com");
  };
  const MadridLasLetras = () => {
    window.open("https://madridlasletras.com");
  };
  const YaraSombreros = () => {
    window.open("https://victorjreyes.com/store");
  };
  return (
    <>
      <section className={sections.wordpress ? "container" : "d-none"}>
        <h2 className="title">Wordpress, Woocommerce, Elementor</h2>
        <div className="cards">
          <div className="card">
            <img
              src="images/arquitecto.jpg"
              alt="Servicios de Extranjeria"
              className="card__image"
            />
            <div className="card__box">
              <button className="card__btn" onClick={Arquitecto}>
                <span>Arquitecto</span>
              </button>
            </div>
          </div>
          <div className="card">
            <img
              src="images/madrid_las_letras.jpg"
              alt="MadridLasLetras"
              className="card__image"
            />
            <div className="card__box">
              <button className="card__btn" onClick={MadridLasLetras}>
                <span>Alquiler Pisos</span>
              </button>
            </div>
          </div>
          <div className="card">
            <img
              src="images/yarasombreros.jpg"
              alt="Tienda Online Sombreros"
              className="card__image"
            />
            <div className="card__box">
              <button className="card__btn" onClick={YaraSombreros}>
                <span>Tienda Online</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wordpress;
